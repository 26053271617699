.root {
  --frame-background-color: var(--color-background-light);

  width: 100%;
  height: auto;
  padding: var(--space-l);
  border-radius: var(--border-radius-default);
  background-color: var(--frame-background-color);
}

.imageWrapper {
  position: relative;
  width: 100%;
  max-width: var(--media-max-width, 920px);
  max-height: var(--media-max-height, 500px);
  aspect-ratio: var(--media-aspect-ratio, 2 / 1);
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.imageWrapper > * {
  width: 100%;
  height: auto;
  object-fit: contain;
  object-position: center;
}

/* VARIATIONS */
.root.dark {
  --frame-background-color: var(--color-background-inverted);
}

.root.hasGradient {
  background-image: linear-gradient(
    to right,
    var(--frame-background-color) 30%,
    var(--frame-gradient-color, var(--color-teal)) 200%
  );
}

.root.hasGradient.invertGradient {
  background-image: linear-gradient(
    to left,
    var(--frame-background-color) 30%,
    var(--frame-gradient-color, var(--color-teal)) 200%
  );
}

.bleedBottom {
  padding-bottom: 0;
}

/* RESPONSIVE */
@media (--medium) {
  .root.large {
    padding: var(--space-xl) var(--space-3xl);
  }
}
