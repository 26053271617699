.root {
  --card-spacing: var(--space-xl);

  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  min-height: 300px;
  margin: 0;
  padding: var(--card-spacing);
  overflow: hidden;
}

.illustration {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.15;
}

.content {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  gap: var(--space-xl);
}

.mainContent {
  display: flex;
  flex-direction: column;
  gap: var(--space-l);
}

.quote {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0;
  gap: var(--space-m);
}

.quoteText {
  text-indent: -0.45em;
}

.logo[data-loaded] {
  filter: blur(0) grayscale(100%);
}

.footer {
  font-family: var(--font-family-mono);
  font-size: var(--text-body-s);
}

.authorPosition {
  color: var(--color-text-secondary);
}

.position {
  margin-right: var(--space-xs);
}

.sections {
  display: flex;
  flex-direction: column;
  gap: var(--space-l);
}

.section {
  display: flex;
  flex-direction: column;
  gap: var(--space-4xs);
}

.sectionHeading {
  color: var(--color-text-secondary);
}

.sectionList {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
  gap: var(--space-4xs);
}

.sectionItem {
  display: flex;
  align-items: center;
  gap: var(--space-2xs);
  font-size: var(--text-body-m);
}

.sectionItemIcon {
  width: 18px;
  height: 18px;
}

.actions {
  display: flex;
  position: relative;
  justify-content: flex-end;
  margin-top: var(--space-m);
  gap: var(--space-s);
}

.actionLink a {
  color: var(--color-text-default);
}

/* Responsive styles */
@media (--medium) {
  .content {
    flex-direction: row;
    gap: var(--space-2xl);
  }

  .mainContent {
    flex: 1;
  }

  .sections {
    flex: 1;
  }

  .author {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }
}
