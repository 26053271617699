.description {
  max-width: 40ch;
  margin-top: var(--space-s-m);
}

.linkColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 200px;
}

.buttonLink {
  margin-top: var(--space-l-xl);
}

.links {
  display: grid;
  grid-gap: var(--space-m) var(--space-m);
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  margin-top: var(--space-m-l);
}

.link {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: var(--space-xs);
  padding-bottom: var(--space-xs);
  border-top: 1px solid var(--color-border);
  color: var(--color-text-secondary);
  text-decoration: none;
  gap: var(--space-xs);
}

.link:hover {
  color: var(--color-text-primary);
}

.linkIcon {
  display: block;
  margin-top: 0.25em;
}

.videoColumn {
  margin-top: var(--space-2xl);
}

@media (--medium) {
  .root {
    display: flex;
    gap: var(--column-gutter);
    height: 100%;
  }

  .linkColumn,
  .videoColumn {
    width: 100%;
  }

  .videoColumn {
    margin-top: 0;
  }
}
