.root {
  display: grid;
  grid-gap: var(--row-gutter) var(--column-gutter);
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: var(--live-data-min-height);
  padding: var(--card-spacing);
}

.card {
  display: block;
  width: 100%;
  padding: var(--space-s-m);
  border-radius: var(--border-radius-default);
  background: var(--format-background);
  color: var(--format-text-color);
  text-decoration: none;
}

.card:hover {
  background: var(--format-background-hover);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  margin-bottom: var(--space-s);
}

.name {
  opacity: 0.85;
  font-family: var(--font-family-mono);
  font-size: var(--text-body-s);
  letter-spacing: 0.04em;
  text-transform: uppercase;
}

.logo {
  opacity: 0.65;
}

.downloads {
  min-height: 200px;
  border-top: 1px solid var(--format-border-color);
  gap: var(--space-s);
}

.downloadsNumbers {
  padding-top: var(--space-s);
}

.downloadsSparkline {
  width: 100%;
  height: auto;
  margin-top: var(--space-s);
  margin-bottom: var(--space-m);
  opacity: 0.65;
}

.downloadsValue {
  display: inline-flex;
  align-items: baseline;
  font-family: var(--font-family-mono);
  font-size: var(--text-headline-l);
  line-height: 1;
}

.downloadsLabel {
  opacity: 0.65;
  font-family: var(--font-family-mono);
  font-size: var(--text-body-xs);
}

.packages {
  margin: 0;
  padding: 0;
  list-style: none;
}

.package {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: var(--space-2xs);
  border-top: 1px solid var(--format-border-color);
}

.package + .package {
  margin-top: var(--space-s);
}

.packageName {
  display: flex;
  flex-direction: column;
  font-family: var(--font-family-headline);
  font-size: var(--text-body-l);
}

.packageNameVersion {
  opacity: 0.65;
  font-family: var(--font-family-mono);
  font-size: var(--text-body-xs);
}

.packageDownloads {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-family: var(--font-family-mono);
  font-size: var(--text-body-m);
}

.packageDownloadsLabel {
  opacity: 0.65;
  font-size: var(--text-body-xs);
}

.link {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: var(--space-l);
  padding-top: var(--space-xs);
  border-top: 1px solid var(--format-border-color);
  opacity: 0.65;
  gap: var(--space-2xs);
}

.card:hover .link {
  opacity: 1;
}

.linkText {
  font-family: var(--font-family-headline);
  font-size: var(--text-body-s);
  text-decoration: underline;
  text-decoration-thickness: 1px;
}

.card:hover .linkText {
  text-decoration: none;
}

@media (--small) {
  .root {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (--medium) {
  .root {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
