.root {
  background: radial-gradient(
    ellipse 72% 107% at center 120%,
    var(--brand-color-blue-2) 0,
    rgba(255, 255, 255, 0) 100%
  );
}

.circle {
  fill: none;
  stroke-dasharray: 2 2;
  stroke: var(--brand-color-blue-4);
  opacity: 0;
}
