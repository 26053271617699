.root > * + * {
  margin-top: var(--row-gutter);
}

.link {
  display: block;
  text-decoration: none;
}

.card {
  padding: var(--card-spacing);
  background: var(--color-background-default);
  color: var(--color-text-default);
  text-decoration: none;
}

.cardPrimary {
  background: var(--color-accent-default);
  color: var(--color-text-on-color);
}

.cardIcon {
  margin-bottom: var(--space-l-xl);
}

.cardPrimary .cardIcon {
  color: currentcolor;
}

.cardTitle {
  margin-bottom: var(--space-xs-s);
}

.cardAction {
  display: inline-block;
  margin-top: var(--space-xs);
  color: var(--color-accent-default);
  font-family: var(--font-family-headline);
  font-size: var(--text-body-m);
}

.cardPrimary .cardAction {
  color: inherit;
}

.card:hover .cardAction {
  text-decoration: underline;
  text-decoration-thickness: 1px;
}

.additionalDetails {
  margin-top: var(--space-xl-2xl);
  padding-top: var(--space-m-l);
  border-top: 1px solid var(--color-border);
}

.additionalDetailsIntro {
  font-size: var(--text-body-l);
}

.emailList {
  margin: var(--space-m) 0 0 0;
  padding: 0;
  list-style: none;
}

.emailList > * + * {
  margin-top: var(--space-xs);
}

.emailLink {
  text-decoration: none;
}

.emailAddress {
  display: block;
  color: var(--color-accent-default);
  font-family: var(--font-family-headline);
  font-size: var(--text-body-l);
  text-decoration: none;
}

.emailLink:hover .emailAddress {
  text-decoration: underline;
  text-decoration-thickness: 1px;
}

.emailCategory {
  display: block;
  color: var(--color-text-tertiary);
  font-size: var(--text-body-s);
}

.postalAddress {
  margin-top: var(--space-m-l);
  padding: var(--space-s-m);
  border-radius: var(--border-radius-default);
  background: var(--color-background-light);
}
