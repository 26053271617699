.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height: 200px;
  margin: 0;
  padding: var(--card-spacing);
}

.quote {
  margin: 0 0 var(--space-xl-2xl) 0;
}

.author {
  display: flex;
  flex-direction: column;
  gap: var(--space-m);
  font-family: var(--font-family-mono);
  font-size: var(--text-body-s);
}

.logoWrapper {
  position: relative;
  width: 75px;
  height: 50px;
  margin-bottom: var(--space-m);

  & > * {
    object-fit: contain;
  }
}

.logo {
  filter: grayscale(100%);
  opacity: 0.5;
}

.footer {
  align-items: baseline;
  color: var(--color-text-secondary);
}

.position,
.company {
  display: inline-block;
}

.position {
  margin-right: var(--space-xs);
}

.linkWrapper {
  opacity: 0.75;
}

@media (--medium) {
  .author {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }
}
