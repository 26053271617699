.root {
  margin: 0;
  padding: 0;
  list-style: none;
}

.item {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: var(--column-gutter);
  padding-top: var(--space-s);
  padding-bottom: var(--space-s);
  border-top: 1px solid var(--color-border);
}

.label {
  display: flex;
  flex-basis: 500px;
  flex-grow: 1;
  align-items: center;
  width: 100%;
  margin-bottom: var(--space-xs);
  padding: 0;
  border: 0;
  background: transparent;
  color: var(--color-text-default);
  font-family: var(--font-family-headline);
  font-size: var(--text-body-l);
  text-align: left;
  cursor: pointer;
  gap: var(--space-xs);
}

.label:hover {
  color: var(--color-accent-default);
}

.icon {
  font-size: var(--text-body-m);
}

.content {
  flex-basis: 0;
  flex-grow: 999;
  max-width: 60ch;
  min-inline-size: 50%;
  font-size: var(--text-body-m);
  line-height: 1.65;
}

.contentText {
  margin-bottom: var(--space-l);
}

.contentActions {
  margin-top: calc(-1 * var(--space-s));
  margin-bottom: var(--space-l);
}

@media (--large) {
  .label {
    font-size: var(--text-body-xl);
  }

  .content {
    font-size: var(--text-body-l);
  }
}
