.root {
  --row-height: 8em;

  margin-right: var(--layout-gutter);
  margin-left: var(--layout-gutter);

  padding-top: var(--space-l-xl);
  padding-bottom: var(--space-xl-2xl);
  border-radius: var(--border-radius-default);
  background: var(--color-background-default);
  color: var(--color-text-default);
}

.text {
  padding: 0 var(--space-l-xl);
}

.links {
  margin-top: var(--space-xl);
}

.card {
  --card-min-height: var(--row-height);

  width: 150px;
}

.marqueeWrapper {
  display: flex;
  flex-direction: column;
  min-height: calc(2 * var(--row-height) + var(--space-s));
  margin: var(--space-xl) 0 0 0;
  gap: var(--space-s);
}

@media (--medium) {
  .root {
    --row-height: 10em;
  }

  .card {
    width: 250px;
  }
}
