.root {
  margin-right: var(--layout-gutter);
  margin-left: var(--layout-gutter);
}

.root[data-border] {
  padding-top: var(--space-s-m);
  border-top: 1px solid var(--color-border);
}

.list {
  margin-top: var(--space-l);
}

.item {
  display: block;
}

.item + .item {
  margin-top: var(--space-2xl);
}

@media (--medium) {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
