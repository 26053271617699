.root {
  background: radial-gradient(
    ellipse 72% 107% at center 120%,
    var(--brand-color-green-2) 0,
    rgba(255, 255, 255, 0) 100%
  );
}

.line {
  font-size: 12px;
  transition: transform 1s ease;
}

.line:nth-of-type(1) {
  transform: translateY(20px);
}

.line:nth-of-type(2) {
  transform: translateY(40px);
}

.line:nth-of-type(3) {
  transform: translateY(60px);
}

.line:nth-of-type(4) {
  transform: translateY(80px);
}

.line:nth-of-type(5) {
  transform: translateY(100px);
}

.root[data-inview] .line {
  transform: translateY(0);
}

.bar {
  fill: var(--brand-color-green-4);
  transform: scaleY(0);
  transform-origin: bottom;
  transition: transform 1s ease 0.5s;
}

.root[data-inview] .bar {
  transform: scaleY(1);
}
