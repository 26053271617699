.root {
  background: radial-gradient(
    66.03% 66.03% at 50% 100%,
    var(--brand-color-blue-3) 0%,
    rgba(183, 210, 255, 0) 100%
  );
}

.ui {
  transition: transform 1s ease;
  transform: translateY(100px);
}

.root[data-inview] .ui {
  transform: translateY(0);
}

.card {
}

.iconDefault,
.iconSpinning,
.iconChecked {
  transition: opacity 0.3s linear;
}

.card .background path {
  transition: fill 0.4s ease;
  fill: var(--brand-color-white);
}

.activeInProgress .background path {
  fill: var(--brand-color-blue-1);
}

/* Phase 1 */
.card.activePhaseOne .iconDefault {
  opacity: 1;
}

.card.activePhaseOne .iconSpinning {
  opacity: 0;
}

.card.activePhaseOne .iconChecked {
  opacity: 0;
}

/* Phase 2 */
.card.activePhaseTwo .iconDefault {
  opacity: 0;
}

.card.activePhaseTwo .iconSpinning {
  opacity: 1;
  animation: spin 1s linear infinite;
}

.card.activePhaseTwo .iconChecked {
  opacity: 0;
}

/* Phase 3 */
.card.activePhaseThree .iconDefault {
  opacity: 0;
}

.card.activePhaseThree .iconSpinning {
  opacity: 0;
}

.card.activePhaseThree .iconChecked {
  opacity: 1;
}

.icons {
  transform: translate(504px, 21px);
}

.iconDefault {
  opacity: 1;
}

.iconSpinning {
  opacity: 0;
  transform-box: fill-box;
  transform-origin: center center;
}

.iconChecked {
  opacity: 0;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
