.root {
  --item-number-size: 2.5em;
  --item-in-between-space: var(--space-2xl);

  margin: 0 var(--layout-gutter) 0 var(--layout-gutter);
  padding: 0;
  list-style: none;
  counter-reset: featureItem;
}

.heading {
  margin-top: 0;
  padding-bottom: var(--space-s);
  color: var(--color-text-default);
}

.item {
  counter-increment: featureItem;
}

.item + .item {
  margin-top: var(--item-in-between-space);
}

.itemWithNumber .itemTextColumn {
  position: relative;
  padding-top: calc(var(--item-number-size) + var(--space-l));
}

.itemWithNumber .itemTextColumn::before {
  content: counter(featureItem);
  display: flex;
  position: absolute;
  top: 0;
  left: calc(var(--item-number-size) * -0.125);
  align-items: center;
  justify-content: center;
  width: var(--item-number-size);
  height: var(--item-number-size);
  margin-right: var(--space-s);
  border: 1px solid var(--color-text-default);
  border-radius: 100%;
  color: var(--color-text-default);
  font-family: var(--font-family-mono);
  font-size: var(--text-body-l);
  font-weight: var(--font-weight-bold);
}

.itemList {
  margin-top: var(--space-s);
}

.itemMediaColumn {
  overflow: hidden;
  border-radius: var(--border-radius-default);
}

/* RESPONSIVE */
@media (--medium) {
  .root {
    --item-number-size: 2em;
    --item-in-between-space: var(--space-xl);
  }

  .item {
    position: relative;
  }

  .itemWithNumber:not(:last-child)::before {
    content: '';
    position: absolute;
    top: calc(var(--item-number-size) + var(--space-s));
    bottom: calc(-1 * var(--item-in-between-space) + var(--space-m));
    left: calc(var(--item-number-size) * 0.5);
    width: 1px;
    opacity: 0.25;
    background-color: var(--color-text-default);
  }

  .itemWithNumber .itemTextColumn {
    padding-top: 0;
    padding-left: calc(var(--item-number-size) + var(--space-l));
  }

  .itemWithNumber .itemTextColumn::before {
    top: calc(var(--item-number-size) * -0.05);
    left: 0;
  }
}
