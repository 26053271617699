.root {
  --notch-size: 38px;
}

.thumbnailContainer {
  position: relative;
  width: 100%;
  padding: 0;
  overflow: hidden;
  border: 0;
  border-radius: var(--border-radius-default);
  background: transparent;
  cursor: pointer;
  aspect-ratio: 16 / 9;
  clip-path: content-box;
}

.thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform var(--transition-default);
}

.root:hover .thumbnail {
  transform: scale(1.0125);
}

.playButton {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  align-items: center;
  justify-content: center;
  width: 88px;
  height: 88px;
  padding: 0;
  transform: translate(-50%, -50%);
  border: 0;
  background: rgb(255 255 255 / 80%);
  color: var(--color-background-inverted);
  font-size: 32px;
  clip-path: polygon(
    0% var(--notch-size),
    var(--notch-size) 0%,
    calc(100% - var(--notch-size)) 0%,
    100% var(--notch-size),
    100% calc(100% - var(--notch-size)),
    calc(100% - var(--notch-size)) 100%,
    var(--notch-size) 100%,
    0% calc(100% - var(--notch-size))
  );
}

.root:hover .playButton {
  background: var(--color-accent-default);
  color: var(--color-text-on-color);
}

.videoWrapper {
  aspect-ratio: 16 / 9;

  & > iframe {
    width: 100%;
    height: 100%;
    border: 0;
  }
}
