.root {
  position: relative;
  margin: 0 var(--layout-gutter);
  padding: var(--space-l-xl) var(--layout-gutter) var(--space-xl-2xl);
  overflow: hidden;
  border-radius: var(--border-radius-small);
  background: var(--color-background-light);
}

.gradient {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  & > * {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.content {
  position: relative;
  z-index: 2;
}

.actions {
  margin-top: var(--space-xl);
}

.carousel {
  margin-top: var(--space-l-xl);
}

/* RESPONSIVE */
@media (--max) {
  .root {
    padding-inline: var(--space-l-xl);
    overflow-x: hidden;
  }
}
