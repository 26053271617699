.root {
  display: block;
  height: 100%;
  color: inherit;
  text-decoration: none;
}

.innerWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-default);
  background: var(--color-background-card);
}

.imageWrapper {
  position: relative;
  flex: 0 0 auto;
  width: 100%;
  overflow: hidden;
  border-radius: var(--border-radius-default) var(--border-radius-default) 0 0;
  aspect-ratio: 16 / 9;

  /* This fixes the weird overflow on scale up issue in Safari */

  clip-path: content-box;
}

.imageWrapper > * {
  width: 100%;
  height: 100%;
  transform: scale(1);
  transition: transform var(--transition-slow);
  object-fit: cover;
  object-position: center;
  will-change: transform;
}

.root:hover .imageWrapper > * {
  transform: scale(1.1);
}

.textWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  padding: var(--card-spacing);
}

.headline {
  margin-bottom: var(--space-xs);
}

.root:hover .headline {
  color: var(--color-accent-default);
}

.button {
  margin-top: var(--space-xl);
}
