.root {
  --feature-row-cell-height: 50px;

  position: relative;
  margin-right: var(--layout-gutter);
  margin-left: var(--layout-gutter);
}

.headerRow {
  display: none;
}

.headerCell {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--space-s);
  border-radius: var(--border-radius-default);
  background-color: var(--color-background-inverted);
  color: var(--color-text-inverted-default);

  &.isSticky {
    border-radius: 0 0 var(--border-radius-default) var(--border-radius-default);
    box-shadow: 0 0 2px rgb(0 0 0 / 10%);
  }
}

.headerCellCustomPrice {
  border: 1px solid var(--color-border);
  background-color: var(--color-background-default);
  color: var(--color-text-default);

  &.isSticky {
    border-top: 0;
  }
}

.planName {
  font-family: var(--font-family-headline);
  font-size: var(--text-headline-2xs);
}

.planPrice {
  font-family: var(--font-family-mono);
  font-size: var(--text-headline-s);
}

.planCustomPrice,
.planPriceSuffix {
  color: var(--color-text-tertiary);
  font-family: var(--font-family-headline);
  font-size: var(--text-body-s);
}

.planAction {
  margin-top: var(--space-s);
}

.featureBlock + .featureBlock {
  margin-top: var(--space-2xl);
}

.featureTitle {
  padding-bottom: var(--space-xs);
  line-height: 1.5;
}

.featureTitleCell {
  display: none;
}

.featureTitleText {
  font-size: var(--text-body-s);
  font-weight: 600;
}

.featureSubtitle {
  color: var(--color-text-tertiary);
  font-size: var(--text-body-s);
}

.featureRow {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: var(--space-s);
  padding-top: var(--space-2xs);
  border-top: 1px solid var(--color-border);
}

.featureRowTitle {
  width: 100%;
  font-size: var(--text-body-s);
}

.featureRowCell {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  width: 25%;
  margin-top: var(--space-s);
}

.featureRowCellPlanName {
  margin-top: var(--space-2xs);
  color: var(--color-text-tertiary);
  font-size: var(--text-body-s);
}

.featureCellTickIcon,
.featureCellCloseIcon {
  font-size: var(--text-body-xl);
}

.featureCellTickIcon {
  color: var(--color-accent-default);
}

.featureCellCloseIcon {
  color: var(--color-text-tertiary);
}

/* RESPONSIVE */
@media (--medium) {
  .headerRow,
  .featureTitleRow,
  .featureRow,
  .featureBlockFooterRow {
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    gap: var(--column-gutter);
  }

  .headerCell {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .headerRow {
    grid-column: span 4;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    width: 100%;
  }

  .headerRow.isSticky {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    align-content: end;
    width: 100%;
    padding: 0 var(--layout-gutter);

    &::before {
      content: '';
    }
  }

  .featureBlock + .featureBlock {
    margin-top: var(--space-xl);
    padding-top: var(--space-xl);
    border-top: 1px solid var(--color-border);
  }

  .featureTitleRow {
    align-items: end;
    margin-bottom: var(--space-m);
  }

  .featureTitleText {
    font-size: var(--text-body-m);
  }

  .featureRow {
    margin-bottom: 0;
    padding-top: 0;
    border-top: 0;
    border-bottom: 1px solid var(--color-border);

    &:last-of-type {
      border-bottom: 0;
    }
  }

  .featureRowCell {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: var(--feature-row-cell-height);
    margin: 0;
    background-color: var(--color-background-light);
    font-size: var(--text-body-s);
  }

  .emptyFeatureRowCell {
    background-color: var(--color-background-faint);
  }

  .firstFeatureRowCell {
    border-radius: var(--border-radius-default) var(--border-radius-default) 0 0;
  }

  .lastFeatureRowCell {
    border-radius: 0 0 var(--border-radius-default) var(--border-radius-default);
  }

  .featureRowCellPlanName {
    display: none;
  }

  .featureRowTitle {
    color: var(--color-text-tertiary);
  }

  .featureBlockFooterCell {
    padding: var(--space-m);
    border-radius: 0 0 var(--border-radius-default) var(--border-radius-default);
    background-color: var(--color-background-light);
  }

  .featureCellCloseIcon {
    opacity: 0;
  }
}
