.root > * {
  margin-top: 0;
  margin-bottom: 0;
}

.root > * + * {
  margin-top: var(--row-gutter);
}

/* SIZE VARIATIONS */
.root.xlarge > * + * {
  margin-top: var(--space-3xl);
}

.root.large > * + * {
  margin-top: var(--space-2xl);
}

.root.medium > * + * {
  margin-top: var(--space-xl);
}

.root.small > * + * {
  margin-top: var(--space-s);
}

.root.xsmall > * + * {
  margin-top: var(--space-xs);
}

/* BORDER VARIATIONS */
.root.withInitialBorder > *,
.root.withBorder > * + * {
  border-top: 1px solid var(--color-border);
}

/* BORDER SIZE VARIATIONS */
.root.xlarge.withInitialBorder > *,
.root.xlarge.withBorder > * + * {
  padding-top: var(--space-2xl);
}

.root.large.withInitialBorder > *,
.root.large.withBorder > * + * {
  padding-top: var(--space-xl);
}

.root.medium.withInitialBorder > *,
.root.medium.withBorder > * + * {
  padding-top: var(--space-l);
}

.root.small.withInitialBorder > *,
.root.small.withBorder > * + * {
  padding-top: var(--space-xs);
}

.root.xsmall.withInitialBorder > *,
.root.xsmall.withBorder > * + * {
  padding-top: var(--space-xs);
}
