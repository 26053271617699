.root {
  position: relative;
  height: calc(2 * var(--divider-height, var(--space-l)));

  /* stylelint-disable-next-line */
  margin-top: 0 !important;
  margin-right: var(--layout-gutter);
  margin-left: var(--layout-gutter);
  border: 0;
}

.root + * {
  /* stylelint-disable-next-line */
  margin-top: 0 !important;
}

/* VARIATIONS */
.root.withBorder::after {
  content: '';
  position: absolute;
  bottom: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--color-border);
}
