.card {
  display: flex;
  flex-direction: column;
  max-width: clamp(300px, 100%, 500px);
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-default);
}

.cardHeader {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
  background: var(--color-background-inverted);
  color: var(--color-text-on-color);
}

.cardIllustration {
  position: relative;
  width: 100%;
  height: 200px;
  margin-top: 0;
  margin-bottom: auto;
}

.cardIllustration > * {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
}

.cardEyebrow {
  position: absolute;
  z-index: 2;
  top: var(--space-s);
  left: var(--space-s);
  padding: var(--space-2xs) var(--space-xs);
  border-radius: 9999px;
  background: var(--color-background-default);
  color: var(--color-text-default);
  font-family: var(--font-family-mono);
  font-size: var(--text-body-xs);
  letter-spacing: 0.05em;
  line-height: 1;
  text-transform: uppercase;
}

.cardPriceRow {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: var(--space-m) var(--space-s) 0;
  line-height: 1;
}

.cardName {
  font-family: var(--font-family-headline);
  font-size: var(--text-headline-xs);
}

.cardPrice {
  font-family: var(--font-family-headline);
  font-size: var(--text-headline-m);
}

.cardStartingPrice,
.cardPriceCustom,
.cardPricePeriod {
  opacity: 0.65;
  font-family: var(--font-family-headline);
  font-size: var(--text-body-s);
}

.cardDescription {
  margin-top: var(--space-s);
  padding: 0 var(--space-s) var(--space-s);
  opacity: 0.65;
  font-size: var(--text-body-s);
}

.cardBody {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
}

.cardFeatures {
  margin: 0;
  padding: 0;
  list-style: none;
}

.cardFeature {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: var(--space-m) var(--space-s);
  border-top: 1px solid var(--color-border);
}

.cardFeatureTitle {
  color: var(--color-text-tertiary);
  font-size: var(--text-body-s);
}

.cardFeatureValue {
  color: var(--color-text-primary);
  font-size: var(--text-body-s);
}

.compactCardAction {
  padding: var(--space-xs) var(--space-s) var(--space-s);
}

/* VARIATIONS */
.card.unobtrusive .cardHeader {
  background: var(--color-background-light);
  color: var(--color-text-primary);
}

.card.large .cardName {
  font-family: var(--font-family-headline);
  font-size: var(--text-headline-m);
}

/* RESPONSIVE */
@media (--medium) {
  .card {
    max-width: unset;
  }

  .cardHeader {
    height: 320px;
  }

  .caption {
    grid-column: span 3;
  }
}

@media (--large) {
  .cardHeader {
    height: 360px;
  }

  .cardPriceRow,
  .cardDescription,
  .cardFeature {
    padding-right: var(--space-m);
    padding-left: var(--space-m);
  }

  .cardName {
    font-size: var(--text-headline-s);
  }

  .cardPrice {
    font-size: var(--text-headline-l);
  }

  .cardPriceCustom,
  .cardPricePeriod,
  .cardFeatureTitle,
  .cardFeatureValue {
    font-size: var(--text-body-m);
  }
}
