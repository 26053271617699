.row {
  display: grid;
  grid-gap: var(--row-gutter) var(--column-gutter);
  grid-template-columns: repeat(var(--grid-columns), 1fr);
}

.column {
  --column-start: 0;
  --column-span: 2;
  --column-start-medium: 0;
  --column-span-medium: 12;
  --column-start-large: 0;
  --column-span-large: 12;

  /* stylelint-disable */
  grid-column-start: var(--column-start);
  grid-column-end: span var(--column-span);
  /* stylelint-enable */
}

.column + .column {
  margin-top: var(--row-gutter);
}

@media (--small) {
  .column {
    /* Disabling stylelint here, because otherwise it will try to rewrite this in
     * shorthand form, which doesn't have the styling effect we need. */
    /* stylelint-disable */
    grid-column-start: var(--column-start-medium);
    grid-column-end: span var(--column-span-medium);
    /* stylelint-enable */
  }

  .column + .column {
    margin-top: 0;
  }
}

@media (--medium) {
  .column {
    /* Disabling stylelint here, because otherwise it will try to rewrite this in
     * shorthand form, which doesn't have the styling effect we need. */
    /* stylelint-disable */
    grid-column-start: var(--column-start-large);
    grid-column-end: span var(--column-span-large);
    /* stylelint-enable */
  }
}
