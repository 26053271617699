.root {
  --tag-font-size: var(--text-body-2xs);

  padding: 0.33em;
  border-radius: 0.25em;
  background: var(--tag-background);
  color: var(--tag-text);

  font-family: var(--font-family-mono);
  font-size: var(--tag-font-size);
  line-height: 1.05;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;

  mix-blend-mode: var(--tag-mix-blend-mode, 'normal');
}

/* VARIANTS */
.root[data-variant='default'] {
  --tag-background: var(--color-background-light);
  --tag-text: var(--color-text-secondary);
  --tag-mix-blend-mode: multiply;
}

.root[data-variant='alert'] {
  --tag-background: var(--color-background-alert);
  --tag-text: var(--color-alert);
}

.root[data-variant='highlight'] {
  --tag-background: var(--color-accent-subdued);
  --tag-text: var(--color-accent-default);
  --tag-mix-blend-mode: multiply;
}

/* INTERACTION */
.root[href]:hover {
  --tag-text: var(--color-accent-default);
  --tag-background: var(--color-accent-subdued);
}
