.root {
  background: var(--base-color-grey-1000);
}

.ui {
  transition: transform 1s ease;
  transform: translateY(100px);
}

.root[data-inview] .ui {
  transform: translateY(-23px);
}

.label {
  fill: white;
  font-family: var(--font-family-mono);
  font-size: 10px;
  letter-spacing: 0.04em;
}

.header {
  opacity: 0.5;
  fill: white;
  font-family: var(--font-family-mono);
  font-size: 11px;
  letter-spacing: 0em;
}

.user {
  fill: white;
  font-family: var(--font-family-body);
  font-size: 14px;
  letter-spacing: 0.0075em;
}

.line {
  stroke: white;
  stroke-width: 1;
  stroke-opacity: 0.15;
}
