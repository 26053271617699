.root {
  --section-gutter: var(--layout-gutter);
  --section-padding-top: 0;
  --section-padding-bottom: 0;

  margin-right: var(--section-gutter);
  margin-left: var(--section-gutter);
  padding-top: var(--section-padding-top);
  padding-bottom: var(--section-padding-bottom);

  position: relative;
}

.root.sectionWidthSmall {
  --section-gutter: var(--layout-gutter-s);
  --section-gutter: var(--layout-gutter-s);
}

.root.withBorder {
  --section-padding-top: var(--space-m);
  border-top: 1px solid var(--color-border);
}

.root.hasMeta .header {
  margin-bottom: var(--space-m);
}

.title {
  margin-bottom: var(--space-xs);
}

.title + .description {
  margin-top: var(--space-m);
}

.list {
  width: 100%;
  margin-top: var(--space-l);
}

.actions {
  margin-top: var(--space-l);
}

.root.hasMeta .content {
  margin-top: var(--space-2xl);
}

/* LAYOUT VARIATIONS */

@media (--medium) {
  .root.horizontalLayout {
    display: grid;
    grid-gap: var(--column-gutter);
    grid-template-columns: repeat(12, 1fr);
  }

  .root.horizontalLayout.sticky {
    position: relative;
    align-items: start;
  }

  .root.horizontalLayout .header,
  .root.horizontalLayout .content {
    margin-top: 0;
  }

  .root.horizontalLayout .header {
    grid-column: span 6;
    margin-bottom: 0;
  }

  .root.horizontalLayout .content {
    grid-column: span 6;
  }

  .root.horizontalLayout.contentWidthWide .header {
    grid-column: span 5;
  }

  .root.horizontalLayout.contentWidthWide .content {
    grid-column: span 7;
  }

  .root.horizontalLayout.sticky .header {
    position: sticky;
    top: calc(var(--layout-sticky-top-offset) + var(--space-m));
    transition: top var(--transition-default);
  }

  .root.verticalLayout.hasMeta .header {
    margin-bottom: var(--space-2xl);
  }

  .root.horizontalLayout.offsetForEyebrow .content {
    margin-top: 1.5rem;
  }

  .root.verticalLayout.actionsOnRight .header {
    display: flex;
    justify-content: space-between;
  }

  .root.verticalLayout.actionsOnRight .text {
    max-width: var(--text-measure-wide);
  }

  .root.verticalLayout.actionsOnRight .actions {
    margin-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: end;
  }

  .title {
    padding-right: var(--space-2xl);
  }

  .description {
    max-width: var(--text-measure-wide);
    padding-right: var(--space-2xl-3xl);
  }
}

@media (--large) {
  .root.horizontalLayout.contentWidthWide .header {
    grid-column: span 4;
  }

  .root.horizontalLayout.contentWidthWide .content {
    grid-column: span 8;
  }
}

/* THEME VARIATIONS */

.root.hasTheme {
  --section-padding-top: var(--space-2xl);
  --section-padding-bottom: var(--space-2xl);

  margin-inline: 0;
  padding-inline: var(--section-gutter);
  border-top: 0;
  background: var(--color-background-default);
  color: var(--color-text-default);
}

.root.hasTheme + .root.hasTheme {
  margin-top: 0;
}

/* GRADIENT VARIATIONS */

.root.hasLinearGradient {
  background-image: linear-gradient(
    to right,
    var(--color-background-default) 30%,
    var(--page-section-gradient-color) 350%
  );
}

.root.hasRadialTopGradient {
  background-image: radial-gradient(
    ellipse 45vw 45vw at center -20vw,
    color-mix(in srgb, var(--page-section-gradient-color) 75%, transparent) 0%,
    var(--color-background-default) 100%
  );
}

.gradientSVGShape {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  pointer-events: none;
}

.gradientSVGShape svg {
  width: 100%;
  height: auto;
}

@media (--large) {
  .gradientOffsetRight .gradientSVGShape svg {
    transform: translateX(calc(-1 * var(--section-gutter)));
  }

  .gradientOffsetLeft .gradientSVGShape svg {
    transform: translateX(var(--section-gutter));
  }
}

/* CUSTOM PADDINGS
   Should overwrite hasTheme paddings
*/

.root.pt2XL {
  --section-padding-top: var(--space-2xl);
}

.root.pt3XL {
  --section-padding-top: var(--space-3xl);
}

.root.pt4XL {
  --section-padding-top: var(--space-4xl);
}

.root.pt5XL {
  --section-padding-top: var(--space-5xl);
}

.root.pt6XL {
  --section-padding-top: var(--space-6xl);
}

.root.pb2XL {
  --section-padding-bottom: var(--space-2xl);
}

.root.pb3XL {
  --section-padding-bottom: var(--space-3xl);
}

.root.pb4XL {
  --section-padding-bottom: var(--space-4xl);
}

.root.pb5XL {
  --section-padding-bottom: var(--space-5xl);
}

.root.pb6XL {
  --section-padding-bottom: var(--space-6xl);
}
