.root {
  margin-right: var(--layout-gutter);
  margin-left: var(--layout-gutter);
}

.root.withBorder {
  padding-top: var(--space-m);
  border-top: 1px solid var(--color-border);
}

.text {
  margin-bottom: var(--space-l);
}

.asideText {
  display: none;
}

/* RESPONSIVE */
@media (--medium) {
  .root {
    display: grid;
    position: relative;
    grid-column-gap: var(--column-gutter);
    grid-template-columns: repeat(2, 1fr);
    align-items: start;
  }

  .text {
    position: sticky;
    top: calc(var(--layout-sticky-top-offset) + var(--space-m));
    transition: top var(--transition-default);
  }

  .asideTextWrapper {
    position: relative;
  }

  .asideText {
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    max-width: 60ch;
    padding-right: var(--space-2xl);
    transition: opacity var(--transition-default);
    opacity: 0;
  }

  .asideTextActive {
    position: unset;
    opacity: 1;
  }
}
