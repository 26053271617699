.root {
  background: radial-gradient(
    100% 100% at 49.91% 100%,
    var(--brand-color-purple-2) 0%,
    rgba(240, 229, 255, 0) 100%
  );
}

.ui {
  transition: transform 1s ease;
  transform: scale(1.4) translateY(100px);
}

.root[data-inview] .ui {
  transform: scale(1.4) translateY(0);
}

.cards {
  transition: transform 0.12s linear;
}
