.root {
  --media-max-height: 400px;

  position: relative;
  margin-right: var(--layout-gutter);
  margin-left: var(--layout-gutter);
}

.root.bleed {
  margin-right: 0;
  margin-left: 0;
}

.root.bleed .text {
  padding-right: var(--layout-gutter);
  padding-left: var(--layout-gutter);
}

.media {
  position: relative;
  aspect-ratio: var(--media-aspect-ratio, 4 / 3);
  margin-top: var(--space-xl);
  overflow: hidden;
  border-radius: var(--border-radius-default);
}

.root.lightTheme .media {
  background: var(--color-background-light);
}

.root.darkTheme .media {
  background: var(--color-background-inverted);
}

.root.accentTheme .media {
  background: var(--color-accent-default);
}

.media > * {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  min-width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transform: translate(-50%, -50%);
}

.eyebrow {
  margin-bottom: var(--space-xs);
}

.actions {
  margin-top: var(--space-m);

  & a {
    font-size: var(--text-body-l);
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.list {
  margin-top: var(--space-m);
}

/* VARIATIONS */
.root.bleed .media {
  width: calc(100% - var(--layout-gutter));
  margin-left: var(--layout-gutter);
  padding-right: 0;
  padding-left: 0;
  border-radius: var(--border-radius-default) 0 0 var(--border-radius-default);
}

.root.bleed.alignRight .media {
  margin-left: 0;
  border-radius: 0 var(--border-radius-default) var(--border-radius-default) 0;
}

/* RESPONSIVE */
@media (--medium) {
  .root {
    display: flex;
    align-items: center;
    gap: var(--column-gutter);
  }

  .text,
  .root.bleed .text {
    width: 50%;
    padding-right: var(--space-xl-2xl);
  }

  .media {
    width: 50%;
    margin-top: 0;
    padding-left: 0;
  }

  .root.bleed .media {
    width: calc(50%);
    margin: 0;
  }

  .root.alignRight {
    flex-direction: row-reverse;
  }

  .root.alignRight .text {
    padding-left: var(--space-xl-2xl);
  }

  .root.alignRight.bleed .text {
    padding-right: var(--layout-gutter);
  }

  .root.alignRight .media {
    padding-right: 0;
  }

  .root.bleed.alignRight .media {
    padding-right: 0;
    padding-left: 0;
  }

  .actions {
    margin-top: var(--space-l);
  }
}

@media (--max) {
  .media > * {
    width: 100%;
    height: auto;
    min-height: 100%;
  }
}
