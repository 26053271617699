.root {
  background: var(--base-color-grey-1000);
}

.ui,
.code {
  transition: transform 1s ease;
}

.ui {
  transform: translateX(-100px);
}

.code {
  font-family: var(--font-family-mono);
  font-size: 12px;
  letter-spacing: -0.02em;
  transform: translateX(100px);
}

.root[data-inview] .ui,
.root[data-inview] .code {
  transform: translateX(0);
}
