.root {
  background: linear-gradient(
      180deg,
      rgba(249, 192, 201, 0) 0%,
      rgba(249, 192, 201, 0.2) 56.35%
    ),
    radial-gradient(91.27% 131.02% at 93.95% 130%, #ffdee4 0%, #fff 100%);
}

.links {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--space-m);
  margin-top: var(--space-xl);
}

.card {
  width: var(--marquee-item-width);
  height: var(--marquee-item-height);
  padding: var(--space-s) var(--space-m);
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.svgMarquee {
  --marquee-item-gap: var(--space-m);

  width: 100%;
  overflow-x: hidden;
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-1 * var(--iteration-width)));
  }
}

@keyframes marqueeReversed {
  from {
    transform: translateX(calc(-1 * var(--iteration-width)));
  }
  to {
    transform: translateX(0);
  }
}

.item {
  width: var(--marquee-item-width);
  height: var(--marquee-item-height);
  margin-top: 10px;
  filter: drop-shadow(0px 8px 10px rgba(255, 97, 131, 0.15));
}

/* This is needed because of a weird marquee
   translation bug in Safari. See #41 */
.item,
.item img {
  transform: translate3d(0, 0, 0);
}

.itemWrapper {
  animation: marquee var(--marquee-speed) linear infinite;
  animation-play-state: paused;
  transform: translate3d(0, 0, 0);
  will-change: transform;
}

.svgMarquee.inView .itemWrapper {
  animation-play-state: running;
}

.svgMarquee.reverse .itemWrapper {
  animation-name: marqueeReversed;
}

.svgMarquee.paused .itemWrapper {
  animation-play-state: paused;
}

@media (--max) {
  .svgMarquee {
    width: 100%;
    max-width: 2000px;
    margin: 0 auto;
  }
}

.logo {
  opacity: 0.75;
  color: var(--color-text-secondary);
}
