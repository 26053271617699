.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 200px;
  margin: 0;
  padding: var(--card-spacing);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-default);
  background-color: var(--color-background-card);
}

.quote {
  margin: 0 0 var(--space-xl-2xl) 0;
}

.author {
  display: flex;
  align-items: flex-end;
  gap: var(--space-s);
  font-family: var(--font-family-mono);
  font-size: var(--text-body-s);
}

.avatar {
  width: 64px;
  height: 64px;
  object-fit: cover;
  border-radius: var(--border-radius-small);
}

.twitterName {
  color: var(--color-text-secondary);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
