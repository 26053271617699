.root {
  --carousel-item-width: min(500px, 90vw);
  --carousel-item-gap: var(--column-gutter);
  --carousel-item: 0;
}

.controls {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: var(--row-gutter);
}

.counter {
  margin-right: var(--space-l);
  color: var(--color-text-default);
  font-family: var(--font-family-mono);
  font-size: var(--text-headline-xs);
  line-height: 1;
}

.counterDivider {
  display: inline-block;
  margin: 0 0.2em;
  opacity: 0.3;
}

.control {
  padding: 0.2em 0.1em;
  transition: opacity var(--transition-fast), color var(--transition-fast);
  border: 0;
  background: transparent;
  color: var(--color-text-tertiary);
  font-size: 24px;
}

.control:not([disabled]):hover {
  color: var(--color-text-default);
  cursor: pointer;
}

.control[disabled] {
  opacity: 0.25;
  cursor: not-allowed;
}

.children {
  margin-right: calc(var(--layout-gutter) * -1);
  margin-left: calc(var(--layout-gutter) * -1);
  padding-left: var(--layout-gutter);
  overflow: hidden;
  user-select: none;
  /* stylelint-disable-next-line */
  -webkit-user-select: none;
}

.itemWrapper {
  display: flex;
  align-items: flex-start;
  gap: var(--carousel-item-gap);
  width: fit-content;
  transform: translateX(
    calc(
      -1 * var(--carousel-item) * (var(--carousel-item-width) +
            var(--carousel-item-gap))
    )
  );
  transition: transform var(--transition-default);
  will-change: transform;
}

.root.stretch .itemWrapper {
  align-items: stretch;
}

.item {
  width: var(--carousel-item-width);

  & > * {
    height: 100%;
  }
}

/* RESPONSIVE */
@media (--medium) {
  .controls {
    justify-content: flex-end;
    margin-top: -2rem;
  }
}
