.root {
  padding: var(--card-spacing);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-default);
  background-color: var(--color-background-card);
}

.text {
  margin-top: var(--space-s);
  margin-bottom: calc(-1 * var(--space-xs));
  color: var(--color-text-secondary);
}

.list {
  margin-top: var(--space-xl);
}
