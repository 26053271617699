.root {
  margin-right: var(--layout-gutter);
  margin-left: var(--layout-gutter);
}

.card + .card {
  margin-top: var(--space-m);
}

/* RESPONSIVE */
@media (--medium) {
  .root {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: var(--column-gutter);
  }

  .card + .card {
    margin-top: 0;
  }
}
