.root {
  background: radial-gradient(
      ellipse 170% 250% at center 285%,
      var(--brand-color-purple-6) 0,
      rgba(255, 255, 255, 0) 100%
    ),
    var(--base-color-grey-1000);
}

.path {
}

.circle {
}

.logo {
}

.check {
  opacity: 0;
}

.pending {
}
