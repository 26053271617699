.wrapper {
  transform: translate(126px, 60px);
}

.packages {
  transform: translateY(170px);
}

.statusMonitor {
  transform: translateX(40px);
}

.statusRibbon {
  transition: fill var(--scanning-ease) ease;
}

.package {
  position: static;
}

.packageLabel {
  transition: opacity var(--scanning-ease) ease;
  opacity: 0;
}

.packageLabelActive {
  opacity: 0.3;
}

.packageScanner {
  opacity: 0;
}

.packageScannerActive {
  animation: scanAnimation var(--scanning-time) linear forwards;
}

@keyframes scanAnimation {
  0% {
    opacity: 0;
    transform: translateY(0);
  }
  25% {
    opacity: 1;
    transform: translateY(0);
  }
  50% {
    opacity: 1;
    transform: translateY(84px);
  }
  75% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(0);
  }
}

.textDefault {
  opacity: 0;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  transform: translateY(-12px);
}

.textDefaultActive {
  opacity: 1;
  transform: translateY(0);
}

.textCustom {
  opacity: 0;
  transform: translateY(0);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.textCustomActive {
  opacity: 1;
  transform: translateY(-12px);
}

.iconDefault {
  opacity: 1;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  transform: translateY(0);
}

.iconDefaultActive {
  opacity: 0;
  transform: translateY(-38px);
}

.iconGreen {
  opacity: 0;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  transform: translateY(0);
}

.iconGreenActive {
  opacity: 1;
  transform: translateY(-38px);
}

.iconOrange {
  opacity: 0;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  transform: translateY(0);
}

.iconOrangeActive {
  opacity: 1;
  transform: translateY(-38px);
}

.iconRed {
  opacity: 0;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  transform: translateY(0);
}

.iconRedActive {
  opacity: 1;
  transform: translateY(-38px);
}

.headingDefault {
  opacity: 0;
  transform: translateY(38px);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.headingDefaultActive {
  opacity: 1;
  transform: translateY(0);
}
