.root {
  display: flex;
  flex-direction: column;
  padding: var(--space-4xl) var(--space-m);
  background-color: var(--color-black);
  color: var(--color-white);
  gap: var(--space-2xl);

  --border-radius-default: 0px;
}

.teaserContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--space-l);
}

@media (--medium) {
  .teaserContent {
    flex-direction: row;
    align-items: center;
  }
}

.header {
  max-width: 530px;
  margin-bottom: var(--space-xl);
}

.logo {
  margin-bottom: var(--space-l);
}

.quote {
  position: relative;
  margin: 0;
  margin-bottom: var(--space-m);
}

.quote::before {
  content: '“';
  position: absolute;
  left: -0.5em;
}

.quote::after {
  content: '”';
}

.quote strong {
  background: linear-gradient(
    90deg,
    var(--brand-color-blue-4, #90b7f6) 0%,
    var(--brand-color-blue-5, #6ca3fe) 100%
  );
  background-clip: text;
  font-weight: 400;
  -webkit-text-fill-color: transparent;
}

.video {
  width: 100%;
  margin-bottom: var(--space-xl);
}

.author {
  font-family: var(--font-family-mono);
  font-size: var(--text-body-s);
}

.authorTitle {
  color: var(--color-text-tertiary);
}

.teaserSections {
  display: grid;
  border: 1px solid var(--brand-color-grey-8);
}

@media (--medium) {
  .teaserSections {
    grid-template-columns: repeat(3, 1fr);
  }
}

.section {
  display: flex;
  flex-direction: column;
  gap: var(--space-s);
  padding: var(--space-m);
}

.section:not(:last-child) {
  border-bottom: 1px solid var(--brand-color-grey-8);
}

@media (--medium) {
  .section:not(:last-child) {
    border-right: 1px solid var(--brand-color-grey-8);
    border-bottom: 0;
  }
}

.sectionTitle {
  color: var(--color-text-tertiary);
  font-family: var(--font-family-mono);
  font-size: var(--text-body-s);
  letter-spacing: var(--letter-spacing-wide);
  text-transform: uppercase;
}

.sectionContent {
  font-size: var(--text-body-m);
}

.sectionList {
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
  margin: 0;
  padding: 0;
  list-style: none;
}

.sectionListItem {
  display: flex;
  align-items: center;
  gap: var(--space-xs);
}

.sectionListItemIcon {
  width: 1.25rem;
  height: 1.25rem;
  color: var(--brand-color-blue-5);
}

.teaserActions {
  display: flex;
  gap: var(--space-m);
  justify-content: center;
  color: var(--brand-color-default);

  --color-accent-default: var(--brand-color-white);
  --color-accent-hover: var(--brand-color-grey-100);
}
