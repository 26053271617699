.root {
  --labelWidth: 100%;
  --cellPadding: var(--space-m);

  display: flex;
  flex-direction: column;
  font-size: var(--text-body-s);
}

.header {
  display: flex;
  flex-direction: row;
  gap: var(--space-xs);
  font-size: var(--text-body-m);
}

.spacer {
  display: none;
}

.headerCell {
  padding: var(--space-m);
  flex: 1;
  border-radius: 12px 12px 0 0;
  border: 1px solid rgba(9, 47, 69, 0.1);
  text-align: center;
}

.highlight {
  background: var(--base-color-blue-500);
  color: white;
}

.rows {
  display: flex;
  flex-direction: column;
}

.featureRow {
  display: flex;
  flex-direction: row;
  gap: var(--space-xs);
  border-bottom: 1px solid rgba(9, 47, 69, 0.1);
  align-items: stretch;

  height: 100%;
  flex: 1;
  flex-wrap: wrap;
  padding: 0;
}

.featureCell {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 1rem 1rem;
  flex: 1;
  text-align: center;
  background: #fafafb;
}

.isFilled {
  background: rgba(241, 243, 246, 1);
}

.tick {
  font-size: 1.5em;
  color: var(--color-accent-default);
}

.cross {
  font-size: 1.5em;
  color: var(--base-color-grey-400);
}

.info {
  top: 0.25rem;
  right: 0.5rem;
  position: absolute;
  cursor: pointer;

  &:hover {
    .hoverText {
      display: block;
    }
  }
}

.hoverText {
  position: absolute;
  left: 50%;
  background: black;
  transform: translateX(-50%);
  width: 200px;
  padding: 0.25rem 0.5rem;
  color: white;
  font-size: 0.75rem;
  z-index: 1;
  display: none;
}

.text {
  .line {
    display: block;
  }
}

.featureTitle {
  background: none;
  flex: 0 0 var(--labelWidth);
  padding-top: var(--cellPadding);
  color: var(--color-text-secondary);
}

@media (--small) {
  .root {
    --labelWidth: 35%;
  }

  .spacer {
    display: block;
    flex: 0 0 var(--labelWidth);
  }

  .featureTitle {
    padding-bottom: var(--cellPadding);
  }
}
