.root {
  display: flex;
  flex-direction: column;
  margin-right: var(--layout-gutter);
  margin-left: var(--layout-gutter);
  gap: var(--space-l);
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--space-s);
}

.caption {
  font-size: var(--text-body-l);
}

@media (--large) {
  .root {
    flex-direction: row;
    align-items: center;
  }
}
