.root {
  display: flex;
  align-items: flex-start;
}

.iconWrapper {
  margin-right: var(--space-m);
}

.icon {
  margin-bottom: var(--space-2xl);
  color: var(--color-accent-default);
  font-size: 1.5rem;
}

.heading {
  margin-bottom: var(--space-s);
}

.list {
  margin-top: var(--space-m);
}

.actions {
  margin-top: var(--space-m);
}

@media (--medium) {
  .actions {
    margin-top: var(--space-l);
  }
}
