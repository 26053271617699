.root {
  --package-mini-card-padding-horizontal: var(--space-s);
  --package-mini-card-padding-vertical: var(--space-s);
  --package-mini-card-aspect-ratio: 4 / 2;

  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: var(--package-mini-card-padding-vertical)
    var(--package-mini-card-padding-horizontal)
    calc(var(--package-mini-card-padding-vertical) * 1.2)
    var(--package-mini-card-padding-horizontal);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-small);
  background: var(--color-background-default);
}

.link {
  color: inherit;
  text-decoration: none;
}

.header {
  margin-bottom: var(--space-xs);
}

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 0;
  font-family: var(--font-family-headline);
  font-size: var(--text-headline-s);
  line-height: 1.05;
  gap: var(--space-xs);
}

.registry {
  display: block;
  flex: 0 0 16px;
  aspect-ratio: 1 / 1;
}

.registry > svg {
  width: 100%;
  height: 100%;
}

.keywords {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0;
  padding: 0 var(--package-card-padding-horizontal) 0 0;
  list-style: none;
  gap: 0.5em;
}

.description {
  max-width: 80ch;
  margin-top: var(--space-xs);
  font-family: var(--font-family-body);
  font-size: var(--text-body-m);
}

.footer {
  margin-top: auto;
  margin-bottom: 0;
  padding-top: var(--space-s);
  color: var(--color-text-secondary);
  font-family: var(--font-family-mono);
  font-size: var(--text-body-xs);
  letter-spacing: 0.0125em;
}

.footerRow {
  display: flex;
  align-items: center;
  gap: var(--space-m);
  margin-top: var(--space-xs-s);
}

.version {
  display: flex;
  gap: var(--space-2xs);
}

.versionDate {
  color: var(--color-text-tertiary);
}

/* INTERACTIONS */
.link:hover .root,
.link:focus .root {
  border-color: var(--color-border-accent);
  background: var(--color-accent-subdued);
}

.link:hover .title,
.link:focus .root {
  color: var(--color-accent-hover);
}

/* RESPONSIVE */
@media (--small) {
  .root {
    aspect-ratio: var(--package-mini-card-aspect-ratio);
  }
}
