.root {
  background: radial-gradient(
    100% 100% at 49.91% 100%,
    var(--brand-color-purple-2) 0%,
    rgba(240, 229, 255, 0) 100%
  );
}

.ui {
  transition: transform 1s ease;
  transform: translateY(100px);
}

.root[data-inview] .ui {
  transform: translateY(0);
}
