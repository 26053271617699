.list {
  margin: 0;
  padding: 0;
  list-style: none;

  & > * + * {
    margin-top: var(--space-m);
  }
}

.item {
  --item-text-color: var(--color-text-default);

  padding-top: var(--space-s);

  border-top: 1px solid var(--color-border);
  color: var(--item-text-color);
}

.item.unobtrusive {
  --item-text-color: var(--color-text-secondary);
}

.itemTitle {
  margin-bottom: var(--space-s);
}

.itemList {
  margin-top: var(--space-s);
}

.actions {
  margin-top: var(--space-m);
}

@media (--medium) {
  .actions {
    margin-top: var(--space-l);
  }
}
