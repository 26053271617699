.root {
  position: relative;
  margin-right: var(--layout-gutter);
  margin-left: var(--layout-gutter);
  padding-top: var(--blockquote-padding);
  padding-left: var(--blockquote-padding);

  &::before {
    content: '“';
    position: absolute;
    top: -0.1em;
    left: var(--blockquote-padding);
    opacity: 0.08;
    font-family: var(--font-family-mono);
    font-size: fluid(88px, 100px);
    line-height: 1;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 24px;
    background: linear-gradient(0deg, #85dabb 0%, rgb(133 218 187 / 0%) 100%);
    clip-path: polygon(
      10px 0%,
      calc(100% - 10px) 0%,
      100% 10px,
      100% calc(100% - 10px),
      calc(100% - 10px) 100%,
      10px 100%,
      0% calc(100% - 10px),
      0% 10px
    );
  }
}

.quote {
  max-width: 40ch;
  margin: 0 0 var(--space-xl-2xl) 0;
}

.author {
  display: flex;
  align-items: center;
  gap: var(--space-m);
  font-family: var(--font-family-mono);
  font-size: var(--text-body-s);
}

.logoWrapper {
  position: relative;
  width: 80px;
  height: 40px;

  & > * {
    object-fit: contain;
  }
}

.logo {
  filter: grayscale(100%);
  opacity: 0.5;
}

.authorPosition {
  color: var(--color-text-secondary);
}
