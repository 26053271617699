.root {
  display: flex;
  flex-direction: column;
  margin-right: var(--layout-gutter);
  margin-left: var(--layout-gutter);
  gap: var(--space-l);
  transform: translateY(calc(-1 * var(--space-xl)));
}

.rowDetails {
  display: flex;
  flex-wrap: wrap;
  padding-top: var(--space-m);
  border-top: 3px solid var(--color-border);
  gap: var(--column-gutter);
}

.rowPrimaryDetail {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--space-s);
}

.rowTitle {
  color: var(--color-text-secondary);
}

.badges {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-xs);
}

.badge {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--space-2xs) var(--space-xs);
  overflow: hidden;
  border-radius: 4px;
  background-color: var(--color-accent-subdued);
  color: var(--color-accent-default);
  font-family: var(--font-family-mono);
  font-size: var(--text-body-xs);
  line-height: 1;
}

.unit {
  color: var(--color-text-secondary);
}

.contactButton {
  padding: 0;
  border: 0;
  background-color: transparent;
  color: var(--color-accent-default);
  font-family: var(--font-family-body);
  font-size: var(--text-body-m);
  cursor: pointer;

  &:hover {
    color: var(--color-accent-hover);
  }
}

/* RESPONSIVE */
@media (--medium) {
  .row {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: var(--column-gutter);
  }

  .rowDetails {
    justify-content: space-between;
  }
}

.rowAdditional {
  & .rowDetails {
    grid-column: 2 / 5;
  }
}

.rowCommittedAdditional {
  & .rowDetails {
    grid-column: 3 / 5;
  }
}
