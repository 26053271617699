/* Need to scope to root to not compete with gridStyles */
.root .column {
  display: flex;
  --column-span-medium: 6;
  --column-span-large: 6;
}

.root .column.firstRow {
  --column-span-medium: 6;
  --column-span-large: 6;
}

@media (--small) {
  .root .column:last-child {
    --column-start-medium: 4;
  }
}

@media (--medium) {
  .root .column:last-child {
    --column-start-large: 4;
  }
}

@media (--normal) {
  .root .column {
    --column-span-medium: 4;
    --column-span-large: 4;
  }

  .root .column.firstRow {
    --column-span-medium: 6;
    --column-span-large: 6;
  }

  .root .column:last-child {
    --column-start-large: 0;
  }
}
