.root {
  row-gap: var(--space-xl);
}

.headline {
  margin-bottom: var(--space-s);
  display: flex;
  align-items: start;
  gap: var(--space-xs);
}

.headline > svg {
  margin-top: 0.135em;
}
