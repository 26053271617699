.marqueeWrapper {
  transform: translateY(0);
}

.card {
  width: var(--marquee-item-width);
  height: var(--marquee-item-height);
  padding: var(--space-s) var(--space-m);
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.svgMarquee {
  width: 100%;
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-1 * var(--iteration-width)));
  }
}

@keyframes marqueeReversed {
  from {
    transform: translateX(calc(-1 * var(--iteration-width)));
  }
  to {
    transform: translateX(0);
  }
}

.item {
  width: var(--marquee-item-width);
  height: var(--marquee-item-height);
}

/* This is needed because of a weird marquee
   translation bug in Safari. See #41 */
.item,
.item img {
  transform: translate3d(0, 0, 0);
}

.itemWrapper {
  animation: marquee var(--marquee-speed) linear infinite;
  animation-play-state: paused;
  transform: translate3d(0, 0, 0);
  will-change: transform;
}

.svgMarquee.inView .itemWrapper {
  animation-play-state: running;
}

.svgMarquee.reverse .itemWrapper {
  animation-name: marqueeReversed;
}

.svgMarquee.paused .itemWrapper {
  animation-play-state: paused;
}
