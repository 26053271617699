.root {
  margin-right: var(--layout-gutter);
  margin-left: var(--layout-gutter);
}

.heading {
  margin-top: 0;
  color: var(--color-text-default);
}

.description {
  padding-bottom: var(--space-l);
  color: var(--color-text-default);
}

.paragraph {
  padding-bottom: var(--space-s);
}

.list {
  margin-top: var(--space-l);
}

.media {
  & img {
    border-radius: var(--border-radius-default);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  }
}
