.root {
  --background: var(--card-background, var(--card-background-theme));

  position: relative;
  margin: 0;
  padding: 0;
  overflow: hidden;
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-default);
  background: var(--background);
}

.root.themeLight {
  --card-background-theme: var(--color-background-light);

  border: 0;
  color: var(--color-text-default);
}

.root.themeDark {
  --card-background-theme: var(--color-background-inverted);

  border: 0;
  color: var(--color-text-on-color);
}

.gradient {
  position: absolute;
  z-index: 1;
  inset: 0;
  pointer-events: none;
}

.gradientRadial {
  background: radial-gradient(
      ellipse 100% 200% at -40% 180%,
      var(--card-gradient) 0,
      rgba(255, 255, 255, 0) 100%
    ),
    var(--background);
}

.children {
  position: relative;
  z-index: 2;
}
