.root {
  --media-max-height: 400px;

  position: relative;
  margin-right: var(--layout-gutter);
  margin-left: var(--layout-gutter);
}

.media {
  position: relative;
  aspect-ratio: var(--media-aspect-ratio, 4 / 3);
  margin-top: var(--space-xl);
  overflow: hidden;
  border-radius: var(--border-radius-default);
}

.root.lightTheme .media {
  background: var(--color-background-light);
}

.root.darkTheme .media {
  background: var(--color-background-inverted);
}

.media > * {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  min-width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transform: translate(-50%, -50%);
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* RESPONSIVE */
@media (--medium) {
  .root {
    display: flex;
    align-items: center;
    gap: var(--column-gutter);
  }

  .media {
    width: 65%;
    margin-top: 0;
    padding-left: 0;
  }
}

@media (--max) {
  .media > * {
    width: 100%;
    height: auto;
    min-height: 100%;
  }
}
