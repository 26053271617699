.root {
  background: radial-gradient(
    ellipse 100% 160% at center 180%,
    var(--brand-color-salmon-3) 0,
    rgba(255, 255, 255, 0) 100%
  );
}

.circle {
}

.path {
  stroke: var(--brand-color-salmon-5);
  stroke-dasharray: 2 2;
  fill: none;
}

.logo {
}
