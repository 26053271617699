.root {
  margin: var(--space-3xl) var(--layout-gutter) 0 var(--layout-gutter);
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
}

.header {
  display: flex;
  flex-direction: row;
  gap: var(--space-xs);
}

.slideButtonsWrapper {
  position: relative;
  flex: 1;
  overflow: hidden;
}

.buttons {
  overflow-x: scroll;
  scrollbar-width: none;
  display: flex;
  flex-direction: row;
  gap: var(--space-xs);
  padding-right: 50px;

  & button {
    white-space: nowrap;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.mask {
  width: 50px;
  pointer-events: none;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) 50%
  );
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
}

.slides {
  position: relative;
  overflow: hidden;
  aspect-ratio: 16 / 9;
}

.slide {
  display: inline-block;
  position: absolute;

  width: 100%;
  opacity: 0;

  &.active {
    opacity: 1;
  }

  & img {
    width: 100%;
    height: auto;
  }
}

@media (--large) {
  .slideButtonsWrapper {
    overflow: hidden;
  }

  .mask {
    display: none;
  }
}
