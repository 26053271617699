.root {
  background: radial-gradient(
    ellipse 73% 107% at center 120%,
    var(--brand-color-green-2) 0,
    rgba(255, 255, 255, 0) 100%
  );
}

.ui {
  transition: transform 1s ease;
  transform: translateY(100px);
}

.root[data-inview] .ui {
  transform: translateY(-30px);
}

.hash {
  fill: var(--base-color-grey-900);
  font-family: var(--font-family-mono);
  font-size: 12px;
  letter-spacing: -0.02em;
}

.label {
  fill: var(--brand-color-green-6);
  font-family: var(--font-family-mono);
  font-size: 10px;
  letter-spacing: 0.04em;
}
