.root {
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-default);
  background-color: var(--color-background-default);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content {
  padding: var(--card-spacing);
}

.text {
  margin-top: var(--space-s);
  color: var(--color-text-default);
}

.actions {
  margin-top: var(--space-m);
}

.media {
  width: 100%;
  aspect-ratio: var(--media-aspect-ratio);
  border-bottom-right-radius: var(--border-radius-default);
  border-bottom-left-radius: var(--border-radius-default);
  overflow: hidden;
  position: relative;
}

/* TODO: Figure out how to make the explainer SVG's fill the space.
   Right now they just align to the bottom.
*/
.media > svg,
.media > div {
  position: absolute;
  bottom: 0;
}
