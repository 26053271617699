.root {
  --progression-card-accent-color: var(--color-accent-default);
  --progression-card-gradient-stop: 250%;
  --progression-card-after-offset: -20px;
  --progression-card-arrow-offset: -40px;
  --progression-card-gradient-progression: 0.5;

  display: flex;
  flex-direction: column;
  padding: var(--card-spacing);
  border-radius: var(--border-radius-default);
  background: linear-gradient(
    180deg,
    var(--color-background-light) 50%,
    var(--progression-card-accent-color)
      calc(
        var(--progression-card-gradient-stop) *
          var(--progression-card-gradient-progression)
      )
  );
}

.root.inView {
  --progression-card-gradient-progression: 1;
  --progression-card-arrow-offset: 0;
  --progression-card-after-offset: 0;
}

.before {
  transition: opacity var(--transition-slow);
  opacity: 1;
}

.root.inView .before {
  opacity: 0.65;
}

.label,
.value {
  display: block;
}

.label {
  margin-bottom: var(--space-2xs);
  color: var(--color-text-secondary);
  line-height: 1.3;
  white-space: initial;
}

.arrow {
  margin: var(--space-l) 0;
  transform: translateY(var(--progression-card-arrow-offset));
  transition: transform var(--transition-slow), opacity var(--transition-slow);
  opacity: 0;
  color: var(--color-text-tertiary);
  font-size: 20px;

  & > svg {
    transform: rotate(90deg);
  }
}

.root.inView .arrow {
  opacity: 1;
}

.after {
  transform: translateY(var(--progression-card-after-offset));
  transition: transform var(--transition-slow), opacity var(--transition-slow);
  opacity: 0;
}

.root.inView .after {
  opacity: 1;
}

@media (--prefers-reduced-motion) {
  .root {
    --progression-card-gradient-progression: 1;
    --progression-card-arrow-offset: 0;
    --progression-card-after-offset: 0;
  }

  .before,
  .arrow,
  .after {
    transition: none;
    opacity: 1;
  }
}

/* RESPONSIVE */
@media (--medium) {
  .root {
    --progression-card-gradient-stop: 200%;

    flex-direction: row;
    align-items: flex-start;
    background: linear-gradient(
      90deg,
      var(--color-background-light) 50%,
      var(--progression-card-accent-color)
        calc(
          var(--progression-card-gradient-stop) *
            var(--progression-card-gradient-progression)
        )
    );
  }

  .before {
    width: 40%;
  }

  .after {
    width: 60%;
    transform: translateX(var(--progression-card-after-offset));
  }

  .arrow {
    align-self: center;
    margin: 0 var(--space-xl) 0 var(--space-l);
    transform: translateX(var(--progression-card-arrow-offset));

    & > svg {
      transform: rotate(0deg);
    }
  }
}
