.root {
  display: flex;
  flex-wrap: wrap;
  gap: var(--column-gutter);
  align-items: center;
  padding: var(--space-2xl);
  margin-left: var(--layout-gutter-s);
  margin-right: var(--layout-gutter-s);
  background: radial-gradient(
      circle 500px at 14% 200%,
      var(--brand-color-salmon-3) 0,
      rgba(255, 255, 255, 0) 100%
    ),
    radial-gradient(
      circle 500px at 111% -80%,
      var(--brand-color-salmon-3) 0,
      rgba(255, 255, 255, 0) 100%
    ),
    var(--brand-color-grey-0);
  border-radius: var(--border-radius-default);
}

.companyImage {
  flex-shrink: 0;
  max-width: 80px;
}

.companyImage img {
  width: 100%;
  height: auto;
}

.text {
  flex-shrink: 0;
}

.title {
  margin-bottom: var(--space-2xs);
}

.awardImages {
  flex: 0 0 100%;
  display: flex;
  gap: var(--space-m);
  justify-content: center;
  flex-wrap: wrap;
  margin-left: var(--space-l);
}

@media (--medium) {
  .root {
    flex-wrap: nowrap;
  }

  .awardImages {
    flex: 1;
    /* gap: var(--space-l); */
  }
}

@media (--layout-s-full) {
  .awardImages {
    gap: var(--space-xl);
    flex-wrap: nowrap;
    justify-content: end;
  }
}
