.root {
  background: radial-gradient(
      ellipse 170% 250% at center 285%,
      var(--brand-color-green-6) 0,
      rgba(255, 255, 255, 0) 100%
    ),
    var(--base-color-grey-1000);
}

.circle {
}

.path {
  stroke: var(--brand-color-grey-5);
  stroke-dasharray: 2 2;
  fill: none;
}

.tick,
.error {
  opacity: 0;
}

.errorBox,
.errorText,
.tickBox,
.tickText {
}
