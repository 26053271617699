.root {
  padding: var(--card-spacing);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-default);
  background-color: var(--color-background-card);
}

.iconWrapper {
  margin-bottom: var(--space-2xl);
}

.text {
  margin-top: var(--space-s);
  color: var(--color-text-default);
}

.list {
  margin-top: var(--space-l);
}

.actions {
  margin-top: var(--space-m);
}

@media (--medium) {
  .icon {
    margin-bottom: var(--space-2xl-3xl);
  }

  .text {
    max-width: 45ch;
  }

  .actions {
    margin-top: var(--space-l);
  }
}
