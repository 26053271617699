.root {
  --format-list-card-width: 200px;

  margin-right: var(--layout-gutter);
  margin-left: var(--layout-gutter);

  border-radius: var(--border-radius-default);
  background: var(--color-background-light);
}

.nav {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: var(--space-s);
  padding: var(--space-m);
  border-bottom: 1px solid rgb(0 0 0 / 10%);
  gap: var(--space-xs) var(--space-2xs);
}

.navButton,
.resetButton {
  padding: 0;
  border: 0;
  background: none;
  font: inherit;
  line-height: 1;
  cursor: pointer;
}

.navButton {
  display: flex;
  align-items: baseline;
  padding: var(--space-xs) var(--space-s);
  border: 1px solid var(--color-text-tertiary);
  border-radius: 99em;
  color: var(--color-text-secondary);
  font-family: var(--font-family-headline);
  font-size: var(--text-body-s);
  gap: var(--space-xs);
}

.navButtonCount {
  opacity: 0.5;
  font-family: var(--font-family-mono);
  font-size: 0.8em;
}

.navButton[aria-current] {
  border-color: var(--color-background-inverted);
  background: var(--color-background-inverted);
  color: var(--color-text-on-color);
}

.navButton:not([aria-current]):hover {
  border-color: var(--color-text-default);
  color: var(--color-text-default);
}

.resetButton {
  margin-right: 0;
  margin-left: auto;
  color: var(--color-text-secondary);
  font-family: var(--font-family-body);
  font-size: var(--text-body-s);
  text-decoration: underline;

  &:hover {
    color: var(--color-text-default);
    text-decoration: none;
  }
}

.categoryInformation {
  padding: var(--space-m);
}

.categoryTitle {
  margin-bottom: var(--space-xs);
  color: var(--color-text-tertiary);
}

.formatList {
  --card-min-height: 8em;

  display: grid;
  grid-gap: var(--space-xs) var(--space-xs);
  grid-template-columns: repeat(
    auto-fit,
    minmax(var(--format-list-card-width), 1fr)
  );
  padding: var(--space-m);
}

.formatList > * {
  flex: 1 1 300px;
}

/* RESPONIVE */
@media (--medium) {
  .root {
    display: flex;
    position: relative;
    padding: 0;
    background: transparent;
  }

  .root.hasActiveCategory {
    --format-list-card-width: 320px;
  }

  .nav {
    position: sticky;
    top: calc(var(--layout-sticky-top-offset) + var(--space-m));
    flex-direction: column;
    align-items: flex-start;
    width: 25%;
    height: 100%;
    padding: var(--space-m) 0 0 0;
    transition: top var(--transition-default);
    border: 0;
    gap: 0.125em;
  }

  .navButton {
    width: 100%;
    padding: var(--space-xs) 0;
    border: 0;
    border-radius: 0;
    color: var(--color-text-default);
    font-size: var(--text-body-l);
    text-align: left;
  }

  .navButtonCount {
    font-size: 0.65em;
  }

  .navButton[aria-current] {
    background: linear-gradient(90deg, transparent 0%, var(--color-teal) 100%);
    color: var(--color-text-default);
  }

  .navButton:not([aria-current]):hover {
    background: linear-gradient(90deg, transparent 0%, var(--color-teal) 150%);
    color: var(--color-text-default);
  }

  .resetButton {
    margin-top: var(--space-l);
    margin-right: auto;
    margin-left: 0;
  }

  .categoryInformation {
    margin-bottom: var(--space-m);
    padding: 0 var(--space-2xs);
  }

  .formatListWrapper {
    width: 75%;
    padding: var(--space-m);
    border-radius: var(--border-radius-default);
    background: var(--color-background-light);
  }

  .formatList {
    --card-min-height: 10em;

    padding: 0;
  }

  .formatList > * {
    height: 100%;
  }
}
